.sponsor {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: var(--gap);
}

@media (max-width: 72em) {
   .sponsor {
      grid-template-columns: repeat(2, 1fr);
   }
}