.galleries-grid {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: var(--gap);
   row-gap: 4rem;
   padding-block: 2rem;
}

.galleries-grid > a:hover {
   transform: scale(1.1);
   text-decoration: none;
}

.galleries-grid article {
   padding-block: 0;
}

.galleries-grid article img {
   aspect-ratio: 1;
	object-fit: cover;
}

.galleries-grid article > h2 {
   color: var(--white);
}

@media (max-width: 72em) {
	.galleries-grid {
		grid-template-columns: 1fr;
	}
}