.workshop-grid {
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   gap: var(--gap);
   row-gap: 4rem;
   padding-block: 2rem;
}

.workshop-grid > a:hover {
   transform: scale(1.1);
   text-decoration: none;
}

.workshop-grid article {
   padding-block: 0;
   text-align: left;
}

.workshop-grid article > h3 {
   color: rgb( var(--secondary) );
	margin-bottom: .5rem;
}
.workshop-grid article > p {
   color: var(--white);
	font-weight: 400;
}

@media (max-width: 72em) {
	.workshop-grid {
		grid-template-columns: 1fr;
	}
}