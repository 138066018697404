/*
*	Author: Travolgi
*	Name: Sled
*	Version: 2.0.5
*/

* {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	list-style: none;
	outline: none;
	box-sizing: border-box;
}

:root {
	--white: #fff;
	--black: #050d0d;
	--primary: 14 ,167, 158;
	--secondary: 175, 9, 83;
	--green: #08b308;
	--overlay: rgba(0,0,0,.5);
	--radius: .8rem;
	--gap: 2.6rem;
	--cubic-bez-4ms: .4s cubic-bezier(.5, .05, .155, .99);
}

::-webkit-scrollbar {
	width: .7rem;
}
::-webkit-scrollbar-track {
	background: transparent; 
}
::-webkit-scrollbar-thumb {
	border-radius: var(--radius);
}
html {
	scrollbar-width: thin;
	scroll-behavior: smooth;
}

body {
	width: 100%;
	min-height: 100vh;
	font-family: 'Montserrat', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	background-color: var(--black);
	color: var(--white);
   overflow-x: hidden;
}
header, footer {
   position: relative;
   display: block;
   text-align: center;
}
section, article, footer {
	padding-block: 2.5rem;
}
#root > footer {
	background: url('./imgs/bg/bg-footer.webp') no-repeat top center;
	background-position: center;
	background-size: cover;
	padding-bottom: 1rem;
}
#root > footer section.mb-2 {
	margin: 0;
	padding-bottom: 0;
}

h1,
.title {
	color: var(--white);
	font-family: 'Montserrat', sans-serif;
	font-size: 4.5rem;
	font-weight: 800;
}
h2,
.title2 {
	font-family: 'Montserrat', sans-serif;
	font-size: 1.6rem;
	font-weight: 600;
}
h3,
.title3 {
	font-size: 1.2rem;
}
a {
	color: rgb( var(--secondary) );
	text-decoration: none;
	font-weight: 600;
	transition: var(--cubic-bez-4ms);
	cursor: pointer;
}
a:hover {
	color: rgba( var(--primary), .8);
	text-decoration: underline;
}

button {
	background-color: rgba( var(--primary), .6);
	cursor: pointer;
}

img {
	width: 100%;
	border-radius: var(--radius);
}
video {
   width: 100%;
   min-height: 8rem;
   border-radius: var(--radius);
   background: var(--overlay);
}
iframe.map {
	width: 100%;
   height: 27rem;
	border-radius: var(--radius);
}

.container {
	padding: 5rem 1rem;
	max-width: 66rem;
	margin-inline: auto;
}

.flex {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .5rem;
}
.grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	gap: var(--gap);
}
.gtc-2-1 {
	grid-template-columns: 1fr .5fr;
}
.gtc-1-2 {
	grid-template-columns: .5fr 1fr;
}
.gtc-x3 {
	grid-template-columns: repeat(3, 1fr);
}
.items-start {
	align-items: start;
}

ul.list li {
	list-style: disc;
	padding-left: .5rem;
	margin-left: 2rem;
}
ul.inline li {
	display: inline-block;
	padding-right: 1rem;
	font-weight: 500;
}

.subtitle {
   font-style: italic;
   font-weight: 400;
	color: rgb( var(--primary) );
}
.lh-08 {
	line-height: .8;
}
.txt-center {
	text-align: center;
}
.txt-left {
	text-align: left;
}
.txt-primary {
	color: rgb( var(--primary) );
}
.txt-secondary {
	color: rgb( var(--secondary) );
}
.mt-1 {
	margin-top: 1rem;
}
.mt-2 {
	margin-top: 2rem;
}
.mb-0 {
	margin-bottom: 0 !important;
}
.mb-2 {
	margin-bottom: 2rem;
}
.p-0 {
	padding: 0;
}
.pb-2 {
	padding-bottom: 2rem;
}

.badge {
	display: inline-block;
   border-radius: 1.5rem;
   padding: .4rem 1.6rem;
   font-style: italic;
   font-weight: 300;
   color: var(--white);
   background-color: rgba( var(--primary), .6);
}
.badge-icon {
	display: inline-block;
   width: 5rem;
   aspect-ratio: 1;
	padding: 1rem;
   border-radius: 50%;
   background: rgb( var(--secondary) );
}
.badge-icon > svg {
   width: 2.5rem;
   height: 2rem;
   padding-top: .5rem;
}

a.badge {
	border: .15rem solid rgba( var(--primary), .6);
	transition: var(--cubic-bez-4ms);
	text-transform: uppercase;
	font-weight: 700;
}
a.badge:hover {
   color: var(--white);
   background: rgb( var(--secondary) );
	border: .15rem solid rgb( var(--primary) );
	text-decoration: none;
}

.relative {
	position: relative;
}

.label {
	background-color: rgb(238, 0, 0);
	position: absolute;
	right: 0;
	top: 1rem;
	color: var(--white);
	font-weight: bold;
	padding: .5rem 1rem;
	z-index: 99;
}
.relative.label {
	position: relative;
	right: unset;
	top: unset;
	display: inline-block;
}

.top-shadow {
   position: absolute;
   inset: 0;
   width: 100%;
   height: 8rem;
   background-image: linear-gradient( var(--overlay), transparent);
}

.flogo img {
	width: 16rem;
	margin-bottom: .8rem;
}
.flogo h3 {
	font-weight: 500;
	margin-bottom: 1.8rem;
}
.tlogo {
	width: 7rem;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	white-space: nowrap;
}

@media (max-width: 72em) {
	article {
		padding-block: 0;
	}
	ul.inline {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem;
	}
	.grid, .gtc-2-1, .gtc-1-2, .gtc-x3 {
		grid-template-columns: 1fr;
	}
}