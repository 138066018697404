.team-vertical {
   display: grid;
   grid-template-columns: 1fr min-content 1.2rem;
   align-items: start;
}

.team-vertical h2 {
   font-size: 2.5rem;
   color: rgb( var(--blue) );
}
.team-vertical h3, .team-vertical h2 {
   writing-mode: vertical-rl;
}