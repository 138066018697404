section.cta {
	display: grid;
	grid-template-columns: 1fr 15rem;
	place-items: center;
	gap: 2rem;
   padding: 5rem 2rem 7rem 2rem;
   margin-bottom: 2rem;
   border-radius: var(--radius);
   color: var(--white);
   background: url('../imgs/slider/slide2.webp');
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
}

section.cta h2 {
	font-family: 'Montserrat', sans-serif;
   font-size: 2rem;
   line-height: 1;
   margin-bottom: 1rem;
}

section.cta > .badge {
	align-self: end;
}

@media (max-width: 72em) {
	section.cta {
		grid-template-columns: 1fr;
	}
}